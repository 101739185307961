import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import styled from "styled-components"

const Intro = styled.div`
    color: #333;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.2;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin: 2rem 0rem 1rem 0rem;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
`

const Body = styled.p`
    margin-bottom: 1rem;
`

const Anchor = styled.a`
  color: #0D5C00;
  font-weight: 600;
  text-decoration: none;
  
  &:hover {
    color: green;
  }
`

class NewsletterPage extends React.Component {
    render() {
      return (
        <Layout>
            <SEO
                title="Subscription successful"
                keywords={[`success`, `confirmation`]}
            />
            <Intro>
                <Headline>You're on the list!</Headline>
                <Body>We'll be sending you our next newsletter shortly.</Body>
                <Anchor href="/best-remote">Return to previous page</Anchor>
            </Intro>
            <img width="100%" src="https://newprag.s3.amazonaws.com/success/success.gif"/>

        </Layout>
        )
    }
}

export default NewsletterPage